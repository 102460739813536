import type { ParsedQuery } from 'query-string';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getBulkListingsById = async ({ listingIds, signal }: { listingIds: string[]; signal?: AbortSignal }) => {
    try {
        const listingResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchBulkListingsById.build(),
            options: {
                method: 'POST',
                body: JSON.stringify({ listingIds }),
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? { options: { signal } } : {})
            }
        });

        return listingResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getListings = async ({ queryParams, signal }: { queryParams: ParsedQuery; signal?: AbortSignal }) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchListings.build({ queryParams }),
            ...(signal
                ? {
                      options: {
                          signal
                      }
                  }
                : {})
        });

        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getListingById = async (id: string, signal?: AbortSignal) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchListingById.build({
                listingId: id
            }),
            ...(signal ? { options: { signal } } : {})
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getListingStatsById = async (id: string, signal?: AbortSignal) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchListingStatsById.build({
                listingId: id
            }),
            ...(signal ? { options: { signal } } : {})
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getRecentlySoldListings = async ({ query = {} }: { query?: ParsedQuery }) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchRecentlySoldListings.build({
                queryParams: query
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const postListingViewByIdRequest = async (
    {
        listingId,
        loggedInUserId
    }: {
        listingId: string;
        loggedInUserId?: string;
    },
    signal?: AbortSignal
) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.postListingViewById.build({
                listingId,
                userId: loggedInUserId
            }),
            options: {
                method: 'POST',
                body: JSON.stringify({
                    loggedInUserId
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? { signal } : {})
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
