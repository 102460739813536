import { ParsedUrlQuery } from 'querystring';
import { ImageType } from '@bladebinge/types';

const hasValue = (value: unknown) => value !== undefined && value !== null;

const defaultPagingParams = ['limit', 'offset', 'sortBy', 'sortDirection'];

const filteredQueryParams = (query: ParsedUrlQuery = {}, includeKeys: string[] = defaultPagingParams) =>
    Object.entries(query).reduce<string[]>((acc, [key, value]) => {
        if (includeKeys.includes(key) && hasValue(value)) {
            acc.push(`${key}:${value}`);
        }

        return acc;
    }, []);

export const adminCachePrimaryKeys = {
    messageById: 'messageById',
    messageThread: 'messageThread'
};

export const adminCacheKeyBuilderMap = {
    messageById: (messageId?: string | null) => [adminCachePrimaryKeys.messageById, messageId],
    messageThread: ({
        query,
        userId,
        parentMessageId
    }: {
        query: ParsedUrlQuery;
        userId: string;
        parentMessageId: string;
    }) => [
        adminCachePrimaryKeys.messageThread,
        ...(userId ? [userId] : []),
        ...(parentMessageId ? [parentMessageId] : []),
        ...filteredQueryParams(query)
    ]
};

export const uiCachePrimaryKeys = {
    addressById: 'addressById',
    bulkListings: 'bulkListings',
    bulkRatings: 'bulkRatings',
    completedOrderById: 'completedOrderById',
    listingById: 'listingById',
    listingsBrowse: 'listingsBrowse',
    listingViewStatsById: 'listingViewStatsById',
    loggedInUser: 'loggedInUser',
    loggedInUserAccount: 'loggedInUserAccount',
    loggedInUserActiveProfile: 'loggedInUserActiveProfile',
    loggedInUserHomePageFavoriteListings: 'loggedInUserHomePageFavoriteListings',
    loggedInUserFavoriteListingIds: 'loggedInUserFavoriteListingIds',
    loggedInUserFavoriteListings: 'loggedInUserFavoriteListings',
    loggedInUserListings: 'loggedInUserListings',
    loggedInUserModerationFlagsSubmitted: 'loggedInUserModerationFlagsSubmitted',
    loggedInUserPersistentUserPreferences: 'loggedInUserPersistentUserPreferences',
    loggedInUserReferralCommissions: 'loggedInUserReferralCommissions',
    loggedInUserUserInterfaceNotificationCounts: 'loggedInUserUserInterfaceNotificationCounts',
    loggedInUserViewedListings: 'loggedInUserViewedListings',
    messageThread: 'messageThread',
    orderShipmentById: 'orderShipmentById',
    priceDroppedListings: 'priceDroppedListings',
    ratingById: 'ratingById',
    receivedMessages: 'receivedMessages',
    recentlySoldListings: 'recentlySoldListings',
    searchFilters: 'searchFilters',
    sellerAddressInfo: 'sellerAddressInfo',
    sentMessages: 'sentMessages',
    userAllUserProfiles: 'userAllUserProfiles',
    userImageUploads: 'userImageUploads',
    userPriceDropNotifications: 'userPriceDropNotifications',
    userProfileAddressBook: 'userProfileAddressBook',
    userProfileDisplayGraph: 'userProfileDisplayGraph',
    userProfileListingsByUsername: 'userProfileListingsByUsername',
    userPurchases: 'userPurchases',
    userPurchasesWithFeedbackNeeded: 'userPurchasesWithFeedbackNeeded',
    userRatingsGiven: 'userRatingsGiven',
    userRatingsReceived: 'userRatingsReceived',
    userReferrer: 'userReferer',
    userSales: 'userSales',
    userSalesWithFeedbackNeeded: 'userSalesWithFeedbackNeeded'
};

export const uiCacheKeyBuilderMap = {
    addressById: ({ addressId }: { addressId: string }) => [uiCachePrimaryKeys.addressById, addressId],
    bulkListings: ({ listingIds }: { listingIds: string[] }) => [uiCachePrimaryKeys.bulkListings, ...listingIds.sort()],
    bulkRatings: ({ ratingIds }: { ratingIds: string[] }) => [uiCachePrimaryKeys.bulkRatings, ...ratingIds.sort()],
    completedOrderById: ({ completedOrderId }: { completedOrderId: string }) => [
        uiCachePrimaryKeys.completedOrderById,
        completedOrderId
    ],
    listingById: ({ listingId }: { listingId?: string | null }) => [
        uiCachePrimaryKeys.listingById,
        ...(listingId ? [listingId] : [])
    ],
    listingViewStatsById: ({ listingId }: { listingId?: string | null }) => [
        uiCachePrimaryKeys.listingViewStatsById,
        ...(listingId ? [listingId] : [])
    ],
    listingsBrowse: ({ query }: { query: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.listingsBrowse,
        ...filteredQueryParams(query, [
            ...defaultPagingParams,
            'activeOnly',
            'filterMode',
            'profileTypes',
            'searchFilters',
            'q',
            'username'
        ])
    ],
    loggedInUser: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUser,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserAccount: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserAccount,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserActiveProfile: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserActiveProfile,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserHomePageFavoriteListings: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query?: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.loggedInUserHomePageFavoriteListings,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query, [...defaultPagingParams, 'includeSold', 'q'])
    ],
    loggedInUserFavoriteListings: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query?: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.loggedInUserFavoriteListings,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query, [...defaultPagingParams, 'includeSold', 'q'])
    ],
    loggedInUserFavoriteListingIds: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserFavoriteListingIds,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserListings: ({ loggedInUserId, query }: { loggedInUserId?: string | null; query?: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.loggedInUserListings,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query, [...defaultPagingParams, 'listingFilters'])
    ],
    loggedInUserModerationFlagsSubmitted: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserModerationFlagsSubmitted,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserPersistentUserPreferences: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserPersistentUserPreferences,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserReferralCommissions: ({
        limit,
        loggedInUserId,
        offset
    }: {
        limit?: string;
        loggedInUserId?: string | null;
        offset?: string;
    }) => [
        uiCachePrimaryKeys.loggedInUserReferralCommissions,
        ...(loggedInUserId ? [loggedInUserId] : []),
        {
            ...(limit ? { limit } : {}),
            ...(offset ? { offset } : {})
        }
    ],
    loggedInUserUserInterfaceNotificationCounts: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.loggedInUserUserInterfaceNotificationCounts,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    loggedInUserViewedListings: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query?: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.loggedInUserViewedListings,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query, [...defaultPagingParams, 'includeSold', 'q'])
    ],
    messageThread: ({
        query,
        parentMessageId,
        userId,
        threadUserIds
    }: {
        query: ParsedUrlQuery;
        parentMessageId: string;
        userId?: string | null;
        threadUserIds?: string[];
    }) => [
        uiCachePrimaryKeys.messageThread,
        ...(userId ? [userId] : []),
        ...(threadUserIds ? [threadUserIds.join(',')] : []),
        ...filteredQueryParams(query)
    ],
    orderShipmentById: ({ orderShipmentId }: { orderShipmentId?: string | null }) => [
        uiCachePrimaryKeys.orderShipmentById,
        ...(orderShipmentId ? [orderShipmentId] : [])
    ],
    priceDroppedListings: ({ query }: { query?: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.priceDroppedListings,
        ...filteredQueryParams(query, [...defaultPagingParams, 'includeSold', 'q'])
    ],
    ratingById: ({ ratingId }: { ratingId: string }) => [uiCachePrimaryKeys.ratingById, ratingId],
    receivedMessages: ({ query, userId }: { query: ParsedUrlQuery; userId?: string | null }) => [
        uiCachePrimaryKeys.receivedMessages,
        ...(userId ? [userId] : []),
        ...filteredQueryParams(query, [...defaultPagingParams, 'isRead'])
    ],
    recentlySoldListings: ({ query }: { query?: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.recentlySoldListings,
        ...filteredQueryParams(query, [...defaultPagingParams, 'q'])
    ],
    searchFilters: () => [uiCachePrimaryKeys.searchFilters],
    sellerAddressInfo: ({ addressId, sellerId }: { addressId: string; sellerId: string }) => [
        uiCachePrimaryKeys.sellerAddressInfo,
        sellerId,
        addressId
    ],
    sentMessages: ({ query, userId }: { query: ParsedUrlQuery; userId?: string | null }) => [
        uiCachePrimaryKeys.sentMessages,
        ...(userId ? [userId] : []),
        ...filteredQueryParams(query)
    ],
    userImageUploads: ({
        imageType,
        loggedInUserId,
        uploadLimit
    }: {
        imageType?: ImageType;
        loggedInUserId?: string | null;
        uploadLimit?: number;
    }) => [
        uiCachePrimaryKeys.userImageUploads,
        ...(imageType ? [imageType] : []),
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...(uploadLimit ? [uploadLimit] : [])
    ],
    userAllUserProfiles: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.userAllUserProfiles,
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    userPriceDropNotifications: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.userPriceDropNotifications,
        loggedInUserId,
        ...filteredQueryParams(query, [...defaultPagingParams])
    ],
    userProfileAddressBook: ({
        loggedInUserId,
        userProfileId
    }: {
        loggedInUserId?: string | null;
        userProfileId?: string | null;
    }) => [
        uiCachePrimaryKeys.userProfileAddressBook,
        ...(userProfileId ? [userProfileId] : []),
        ...(loggedInUserId ? [loggedInUserId] : [])
    ],
    userProfileDisplayGraph: ({ userProfileId }: { userProfileId?: string | null }) => [
        uiCachePrimaryKeys.userProfileDisplayGraph,
        ...(userProfileId ? [userProfileId] : [])
    ],
    userProfileListingsByUsername: ({ query, username }: { query: ParsedUrlQuery; username: string }) => [
        uiCachePrimaryKeys.userProfileListingsByUsername,
        username,
        ...filteredQueryParams(query)
    ],
    userPurchases: ({ loggedInUserId, query }: { loggedInUserId?: string | null; query: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.userPurchases,
        loggedInUserId,
        ...filteredQueryParams(query)
    ],
    userPurchasesWithFeedbackNeeded: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.userPurchasesWithFeedbackNeeded,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query)
    ],
    userRatingsGiven: ({ loggedInUserId, query }: { loggedInUserId?: string | null; query: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.userRatingsGiven,
        loggedInUserId,
        ...filteredQueryParams(query)
    ],
    userRatingsReceived: ({ loggedInUserId, query }: { loggedInUserId?: string | null; query: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.userRatingsReceived,
        loggedInUserId,
        ...filteredQueryParams(query)
    ],
    userReferrer: ({ loggedInUserId }: { loggedInUserId?: string | null }) => [
        uiCachePrimaryKeys.userReferrer,
        ...(loggedInUserId ? [loggedInUserId] : ['unknown'])
    ],
    userSales: ({ loggedInUserId, query }: { loggedInUserId?: string | null; query: ParsedUrlQuery }) => [
        uiCachePrimaryKeys.userSales,
        loggedInUserId,
        ...filteredQueryParams(query, [
            ...defaultPagingParams,
            'showCancelled',
            'showDelivered',
            'showInTransit',
            'showNeedsLabel',
            'showNeedsShipping',
            'showShipmentError'
        ])
    ],
    userSalesWithFeedbackNeeded: ({
        loggedInUserId,
        query
    }: {
        loggedInUserId?: string | null;
        query: ParsedUrlQuery;
    }) => [
        uiCachePrimaryKeys.userSalesWithFeedbackNeeded,
        ...(loggedInUserId ? [loggedInUserId] : []),
        ...filteredQueryParams(query)
    ]
};
